// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-song-template-js": () => import("/opt/build/repo/src/templates/song-template.js" /* webpackChunkName: "component---src-templates-song-template-js" */),
  "component---src-templates-tag-template-js": () => import("/opt/build/repo/src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-author-template-js": () => import("/opt/build/repo/src/templates/author-template.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-songs-js": () => import("/opt/build/repo/src/pages/all-songs.js" /* webpackChunkName: "component---src-pages-all-songs-js" */),
  "component---src-pages-authors-js": () => import("/opt/build/repo/src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

